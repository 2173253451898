#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    padding-bottom: 12px;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    border: 0px;
    z-index: 1050;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
    color: rgb(255, 255, 255);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
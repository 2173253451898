#backdrop {
    .backdrop {
        position: fixed;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        color: rgb(255, 255, 255);
        z-index: 1201;
    }
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Light.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Regular.woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-SemiBold.otf');
    font-weight: 600;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Bold.woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-ExtraBold.otf');
    font-weight: 800;
}
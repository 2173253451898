header {
    margin-top: -74px;
}

#home-banner-container.row {
    display: relative;
    background: linear-gradient(90deg, #333, #111);
    overflow-x: hidden;

    height: calc(100vh);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;

    background-image: url(../img/neven-krcmarek-V4EOZj7g1gw-unsplash.jpg);
}

.logo-navbar {
    color: #fff;
    text-shadow: -1px 0 $primary, 0 1px $primary, 1px 0 $primary, 0 -2px $primary;
    letter-spacing: 4;
    line-height: 1.25;
    font-size: 2em;
    font-weight: bold;
}

.logo-navbar-mobile {		
    color: #fff;
    text-shadow: -1px 0 $primary, 0 1px $primary, 1px 0 $primary, 0 -2px $primary;
    letter-spacing: 4;
    line-height: 0.9;
    font-size: 1.6em;
    font-weight: bold;
    white-space: pre;
}

.logo-footer {
    color: $secondary;
    text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -2px #333;
    letter-spacing: 4;
    line-height: 1.25;
    font-size: 2em;
    font-weight: bold;
}

.logo-mobile-text-pot {
    font-size: 0.75em;
}

.custom-position {
    top: 40% !important;
    left: 10% !important;

    @media only screen and (min-width: 396px) and (max-width: 575px) {
        top: 48% !important;
        left: 15% !important;
    }
    @include media-breakpoint-up(sm) {
        top: 43% !important;
        left: 26% !important;
    }
}

#home-title-container {
    h1 {
        letter-spacing: 4;
        line-height: 1.25;
        text-shadow: 4px 4px 7px #000;
        font-size: 3.5em;
        @include media-breakpoint-up(sm) {
            font-size: 4em;
        }
        @include media-breakpoint-up(md) {
            font-size: 4.5em;
        }
        @include media-breakpoint-up(lg) {
            font-size: 5em;
        }
        @include media-breakpoint-up(xl) {
            font-size: 5.5em;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 6.5em;
        }
    }
}

.title-caret,
.subtitle-caret {
  margin-left: -55px;
  font-weight: bold;
  color: white;
  opacity: 1;
  animation: blink 1s step-end infinite;
  @media screen and (max-width: 992px) {
    margin-left: -30px;
  }
}
.subtitle-caret {
  margin-left: 0px;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}

#home-subtitle-container {
    background: #0009;
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#home-catchphrase-right {
    background-image: url(https://drive.google.com/uc?id=1606SIsJMG4FQLSRbQah0t309kW21Cl5o);
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;

    @include media-breakpoint-down(md) {
        height: 300px;
    }
    @include media-breakpoint-down(sm) {
        height: 220px;
    }
}

.card-500 {
    height: 500px;
    @media screen and (max-width: 992px) {
        height: auto;
    }
}

.card-600 {
    height: 600px;
    @media screen and (max-width: 992px) {
        height: auto;
    }
}

.card-min-600 {
    min-height: 600px;
    @media screen and (max-width: 992px) {
        min-height: 300px;
    }
}

#services {
    .card {
        min-height: 350px;
        @media screen and (max-width: 992px) {
            min-height: 300px;
        }
    }
}

#options {
    .card {
        min-height: 440px;
        @media screen and (max-width: 992px) {
            min-height: 300px;
        }
    }
}
